//prod
 export const environment = {
   production: true,
   corsKey: "",
   tenantId: "",
   corsUrl: "https://api.acmeticketing.com/v2/b2c/cors/checkout",
   captcha: "6LfTvpgUAAAAABCSDZOLtww0i8C4Ug-3m_WbS4Mf",
   url: "https://buy.acmeticketing.com/orders/514",
   whfUrl:"https://events-dev.wisconsinhistory.org"
 };

//test
//export const environment = {
//  production: false,
//  corsKey: "",
//  tenantId: "",
//  corsUrl: "https://sand10-api.acmeticketing.net/v2/b2c/cors/checkout",
//  captcha: "6LcFf5gUAAAAAJCGROIYJvD2BxgvqKvCSGmxDvKU",
//  url: "https://sand10-buy.acmeticketing.net/orders/514",
//  whfUrl:"https://events-dev.wisconsinhistory.org"
//};