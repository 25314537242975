import { formatDate, DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { fi } from 'date-fns/locale';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../Services/utility.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  @ViewChild('datepickerpopup', { static: true }) thankyouMessage!: ElementRef;

  formState: string;
  canBuy = false;
  time = new Date();
  model: any = {};
  memberNumber: string = null;
  memberEmail: string = null;
  minDate: Date = new Date();
  eventId: string = null;
  catagory: string = null;
  events: any = null;
  selectedEvent: any = null;
  selectedTime: any = null;
  errorMessage = "";
  errorValidating = false;
  errorValMessage = "";
  showTimeError = false;
  lastDayOfWeek = null;
  lastDayOfNextWeek = null;
  firstDayOfWeek = null;

  url = environment.url;
  whfUrl = environment.whfUrl;

  today = new Date();

  locationArr = [];
  eventTypes = [];

  siteGeoLocations = [];
  locationMatches = [];

  filterdEvents = [];

  includeFilteredEvents = [];

  selectedLocation: string = null;
  selectedEventType: string = null;
  selectedDateRange: string = "All";
  searchKeyword: string = "";

  rangeSelected = false;

  full: boolean = true;
  widget: boolean = false;

  hideWidget: boolean = false; //Decides whether to hide widget depending if there are events to display.

  //Geolocation of each site
  siteLocations = '{ "geoLocations" : [' +
    '{ "site":"Black Point Estate" , "geoLocation":"Lake Geneva, WI" },' +
    '{ "site":"Wade House" , "geoLocation":"Greenbush, WI" },' +
    '{ "site":"First Capitol" , "geoLocation":"Belmont, WI" },' +
    '{ "site":"H.H. Bennett Studio" , "geoLocation":"Wisconsin Dells, WI" },' +
    '{ "site":"Madeline Island Museum" , "geoLocation":"La Pointe, WI" },' +
    '{ "site":"Old World Wisconsin" , "geoLocation":"Eagle, WI" },' +
    '{ "site":"Pendarvis" , "geoLocation":"Mineral Point, WI" },' +
    '{ "site":"Reed School" , "geoLocation":"Neillsville, WI" },' +
    '{ "site":"Stonefield" , "geoLocation":"Cassville, WI" },' +
    '{ "site":"Villa Louis" , "geoLocation":"Prairie du Chien, WI" },' +
    '{ "site":"Circus World" , "geoLocation":"Baraboo, WI" },' +
    '{ "site":"Programs & Outreach" , "geoLocation":"In-Person & Virtual" },' +
    '{ "site":"History Maker Space" , "geoLocation":"Madison, WI" } ]}';

  //This will be used so that I can display these subtypes without the (...) when filtering on the app
  eventSubTypeConvert = '{ "eventSubTypes" : [' +
    '{ "subtype":"General Admission (GA)" , "convertedType":"General Admission" },' +
    '{ "subtype":"School Tour (K-12)" , "convertedType":"School Tour" },' +
    '{ "subtype":"Virtual School Tours (K-12)" , "convertedType":"Virtual School Tours" },' +
    '{ "subtype":"Day Camps (K-12)" , "convertedType":"Day Camps" },' +
    '{ "subtype":"Off-site K-12 Event (K-12)" , "convertedType":"Off-Site K-12 Event" },' +
    '{ "subtype":"Special Event (Program)" , "convertedType":"Special Event" },' +
    '{ "subtype":"Walking Tour (Program)" , "convertedType":"Walking Tour" },' +
    '{ "subtype":"Group Tour (Program)" , "convertedType":"Group Tour" },' +
    '{ "subtype":"Conference (Program)" , "convertedType":"Conference" },' +
    '{ "subtype":"Bathroom/Café/Retail (Aux)" , "convertedType":"Bathrrom/Café/Retail" },' +
    '{ "subtype":"Facility Rental Visitor (Aux)" , "convertedType":"Facility Rental Visitor" } ]}';

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  dateOptions = [
    "All",
    "This Week",
    "Next Week",
    "This Month",
    "Next Month"
  ];

  constructor(public http: HttpClient, public utilityService: UtilityService, public cdr: ChangeDetectorRef, private datePipe: DatePipe) {
    this.setUpDates();
    this.getLinks();
    this.selectedEventType = this.utilityService.getParameterByName('eventtype');
    //this.getEvents();
    this.full = utilityService.fullwidth;
    this.widget = utilityService.widget;
    this.getEventsWithCal(utilityService.location);
  }


  ngOnInit() {
  }

  reset() {
    this.utilityService.loading = false;
    this.model = {};
    this.time = new Date();
    this.formState = null;
    this.canBuy = false;
    this.memberNumber = null;
    this.memberEmail = null;
    this.errorMessage = "";
    this.errorValidating = false;
    this.errorValMessage = "";
    this.showTimeError = false;
    this.lastDayOfWeek = null;
    this.lastDayOfNextWeek = null;
    this.firstDayOfWeek = null;
    this.rangeSelected = false;
    this.full = true;
    this.range = new FormGroup({
      start: new FormControl(),
      end: new FormControl()
    });

  }

  isDateInThisWeek(date) {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();

    // get first date of week
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    // if date is equal or within the first and last dates of the week
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
  }

  isDateInNextWeek(date) {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();

    // get first date of week
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
    const lastDayOfNextWeek = new Date(lastDayOfWeek);
    lastDayOfNextWeek.setDate(lastDayOfWeek.getDate() + 7);

    // if date is equal or within the first and last dates of the week
    return date >= lastDayOfWeek && date <= lastDayOfNextWeek;
  }

  openlink(id) {
    window.open(environment.url + '/calendar?eventId=' + id + '&cart', '_blank');
  }
  openExternalLink(url) {
    window.open(url);
  }

  setUpDates() {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();

    // get first date of week
    this.firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

    // get last date of week
    this.lastDayOfWeek = new Date(this.firstDayOfWeek);
    this.lastDayOfWeek.setDate(this.lastDayOfWeek.getDate() + 6);
    this.lastDayOfNextWeek = new Date(this.lastDayOfWeek);
    this.lastDayOfNextWeek.setDate(this.lastDayOfWeek.getDate() + 7);
  }

  updateDateRange() {
    if (this.range.value.start && this.range.value.end) {
      this.selectedDateRange = formatDate(this.range.value.start, 'MM/dd/yyyy', 'en-US') + ' - ' + formatDate(this.range.value.end, 'MM/dd/yyyy', 'en-US');
      this.rangeSelected = true;
    } else if (!['All', 'This Week', 'This Month', 'Next Week', 'Next Month'].includes(this.selectedDateRange)) {
      this.selectedDateRange = 'All';
      this.rangeSelected = false;
    }

    this.applyFilters();

  }

  resetRange() {
    this.rangeSelected = false;
    this.range = new FormGroup({
      start: new FormControl(),
      end: new FormControl()
    });
  }

  setupFiltersArrays() {
    this.locationArr = [];
    this.eventTypes = [];

    if (this.events) {
      this.events.forEach(event => {

        event.start = formatDate(event.startTime, 'yyyy-MM-dd', 'en-US');
        event.end = formatDate(event.endTime, 'yyyy-MM-dd', 'en-US');


        if (event.calendar.days.length != 0) { //Used to display the dates on the events differently
          const eventDate = new Date(event.calendar.days[0].date);
          const currDate = new Date();
          event.currYear = currDate.getFullYear();
          event.eventYear = eventDate.getFullYear();
        }


        if (event.customFields) {

          event.customFields.forEach(cf => {

            if (cf.name == "Field 4" && cf.value == "Exclude") { //Finding all events with custom field 4
              // and adding an exclude variable.
              event.excludeBool = true;
            }

            if (cf.name == "Event Sub Type") { // Still working on making this work.
              const convert = JSON.parse(this.eventSubTypeConvert);
              const currSubType = cf.value;

              for (const i in convert) { //Iterating through siteLocations JSON 
                for (const j in convert[i]) {

                  if (convert[i][j].subtype === currSubType) {
                    event.simpleSubType = [convert[i][j].convertedType];
                    this.eventTypes.push(convert[i][j].convertedType);
                  }
                }
              }
            }

            if (cf.name == "Location") {
              let currSite = cf.value;
              this.locationArr.push(cf.value);

              const sites = JSON.parse(this.siteLocations); //Creation of JSON Object

              for (var i in sites) { //Iterating through siteLocations JSON 
                for (var j in sites[i]) {

                  if (sites[i][j].site === currSite) {
                    this.locationMatches.push(sites[i][j].geoLocation);

                    event.location = [sites[i][j].geoLocation]; //Adding geoLocation to event object
                    event.siteName = [sites[i][j].site]; // Adding site name to event object
                  }
                }
              }

              event.showLocation = true; // Adding showLocation variable to each event object

            }
          });
        }
      });
    }

    this.locationArr = [...new Set(this.locationArr)];
    this.locationArr.sort();
    this.eventTypes = [...new Set(this.eventTypes)];
    this.eventTypes.sort();

    this.applyFilters();

  }


  applyFilters() {

    if (this.hideWidget == true) { //Resetting the hidewidget boolean
      this.hideWidget = false;
    }

    var self = this;
    console.log('filtering', this.events);

    let fullList = this.events;

    //Variables for control flow of coalescing of events for both include & exclude
    let includeFirstPass: boolean = false;
    let includeSecondPass: boolean = false;

    for (var i in fullList) { //Resetting the showLocation variable to TRUE;
      fullList[i].showLocation = true;
    }

    fullList = fullList.filter(event => event.excludeBool != true); // Filtering the events with customField4

    if (this.selectedLocation != null) {

      // console.log("Filtering By Selected Location!");
      // console.log(this.selectedLocation);

      fullList = fullList.filter(function (e) {

        if (self.selectedLocation == e.siteName) {
          e.showLocation = false;
        }

        let location = e.customFields.filter(c => {
          return c.name == "Location" && c.value == self.selectedLocation
        });

        return location && location.length > 0;
      });

    }

    if (this.selectedEventType != null) { //Filters by event sub type now

      fullList = fullList.filter(function (e) {

        let EventType = e.customFields.filter(c => {
          return c.name == "Event Sub Type" && e.simpleSubType == self.selectedEventType
        });

        return location && EventType.length > 0;
      });

    }

    if (this.selectedDateRange && this.rangeSelected) {
      fullList = fullList.filter(function (e) {
        let startRange = new Date(self.range.value.start);
        let endRange = new Date(self.range.value.end);

        let found = false;
        e.calendar.days.every(day => {
          let eventDay = new Date(day.date);

          if (eventDay >= startRange && eventDay <= endRange) found = true;
        });

        return found;
      });
    } else if (this.selectedDateRange && this.selectedDateRange != "All") {
      fullList = fullList.filter(function (e) {
        if ((!e.calendar && !e.calendar.days) || e.calendar.days.length == 0) {
          return false;
        }
        let start = new Date(e.calendar.days[0].date);
        let end = new Date(e.endTime);


        let thisweek = Number.parseInt(self.datePipe.transform(self.today, 'w'));
        let startweek = Number.parseInt(self.datePipe.transform(start, 'w'));
        let endweek = Number.parseInt(self.datePipe.transform(end, 'w'));
        let nextweek = thisweek + 1;

        let thismonth = Number.parseInt(self.datePipe.transform(self.today, 'M'));
        let startmonth = Number.parseInt(self.datePipe.transform(start, 'M'));
        let endmonth = Number.parseInt(self.datePipe.transform(end, 'M'));
        let nextmonth = thismonth + 1 % 12;

        let startRange = new Date(self.range.value.start);
        let endRange = new Date(self.range.value.end);

        if (self.selectedDateRange == "This Week") {
          startRange = new Date(self.range.value.start);
          endRange = new Date(self.range.value.end);
          return startweek <= thisweek && endweek >= thisweek;
        }
        if (self.selectedDateRange == "Next Week") {
          return startweek <= nextweek && endweek >= nextweek;
        }
        if (self.selectedDateRange == "This Month") {
          return startmonth <= thismonth && endmonth >= thismonth;
        }
        if (self.selectedDateRange == "Next Month") {
          return startmonth <= nextmonth && endmonth >= nextmonth;
        }

        /*e.calendar.days.forEach(day => {
          let eventDay = new Date(day.date);
          if (eventDay >= startRange && eventDay <= endRange) return true;
        });*/

        return false;




      });
    }


    if (this.searchKeyword && this.searchKeyword != undefined && this.searchKeyword.trim() != "") {
      fullList = fullList.filter(function (e) {
        let location = e.name.toLowerCase().includes(self.searchKeyword.toLowerCase());
        let description = e.description.toLowerCase().includes(self.searchKeyword.toLowerCase());
        return location || description;
      });
    }

    let allEventArray = fullList;

    /* ---------------INCLUSIONS------------------- */
    if (this.utilityService.includeBool) {

      if (this.utilityService.includeLocation) {
        includeFirstPass = true;
        let locationsArray = this.utilityService.includeLocation.split(":");
        // console.log("Here in location inclusions");
        // console.log(locationsArray);

        for (const i in locationsArray) {
          for (const j in allEventArray) {
            if (allEventArray[j].siteName == locationsArray[i].trim()) {
              this.includeFilteredEvents.push(allEventArray[j]);
            }
          }
        }
        //console.log(this.includeFilteredEvents);
        allEventArray = this.includeFilteredEvents;

      }

      if (this.utilityService.includeEventType) {
        includeSecondPass = true;
        let eventTypeArray = this.utilityService.includeEventType.split(":");
        // console.log("Here in event type inclusions!");
        // console.log(eventTypeArray);

        if (includeFirstPass) {
          this.includeFilteredEvents = []; // Emptying the array to prepare for the next events
        }

        for (const i in eventTypeArray) {
          for (const j in allEventArray) {
            allEventArray[j].customFields.forEach(cf => {
              if (cf.name == "Event Type" && cf.value == eventTypeArray[i].trim()) {
                this.includeFilteredEvents.push(allEventArray[j]);
              }
            });
          }
        }

        //console.log(this.includeFilteredEvents);
        allEventArray = this.includeFilteredEvents;
      }

      if (this.utilityService.includeEventSubType) {
        let eventSubTypeArray = this.utilityService.includeEventSubType.split(":");
        //console.log("Here in event sub type inclusions!");
        //console.log(eventSubTypeArray);

        if (includeFirstPass && includeSecondPass || includeFirstPass || includeSecondPass) {
          this.includeFilteredEvents = [];
        }

        if (eventSubTypeArray) {
          this.includeFilteredEvents = [];
        }

        for (const i in eventSubTypeArray) {
          for (const j in allEventArray) {
            allEventArray[j].customFields.forEach(cf => {
              if (cf.name == "Event Sub Type" && cf.value == eventSubTypeArray[i].trim()) {
                this.includeFilteredEvents.push(allEventArray[j]);
              }
            });
          }
        }
        //console.log(this.includeFilteredEvents);
        allEventArray = this.includeFilteredEvents;
        fullList = allEventArray;
      }

      if (!this.utilityService.excludeBool) {
        // console.log("Setting events");
        // console.log(allEventArray);
        this.filterdEvents = allEventArray;
      }


    }
    /* -----------------EXLUSIONS--------------------- */
    if (this.utilityService.excludeBool) {
      if (this.utilityService.excludeLocation) {
        let locationsArray = this.utilityService.excludeLocation.split(":");
        let eventsToFilter = allEventArray;
        // console.log("Excluding Locations");
        // console.log(locationsArray);

        for (var i in locationsArray) {
          eventsToFilter = eventsToFilter.filter(e => e.siteName != locationsArray[i].trim());
        }
        allEventArray = eventsToFilter;
        //console.log(allEventArray);
      }

      if (this.utilityService.excludeEventType) {
        let eventTypeArray = this.utilityService.excludeEventType.split(":");
        let eventsToFilter = allEventArray;
        // console.log("Excluding by Event Type!");
        // console.log(eventTypeArray);

        for (var i in eventTypeArray) {

          eventsToFilter = eventsToFilter.filter(function (e) {
            let eventTypes = e.customFields.filter(cf => {
              return cf.name == "Event Type" && cf.value != eventTypeArray[i].trim()
            });
            return eventTypes && eventTypes.length > 0;
          });
        }
        //console.log(eventsToFilter);
        allEventArray = eventsToFilter;

      }

      if (this.utilityService.excludeEventSubType) {
        let eventSubTypeArray = this.utilityService.excludeEventSubType.split(":");
        let eventsToFilter = allEventArray;
        // console.log("Excluding by Event Sub Type!");
        // console.log(eventSubTypeArray);

        for (var i in eventSubTypeArray) {

          eventsToFilter = eventsToFilter.filter(function (e) {
            let eventSubTypes = e.customFields.filter(cf => {
              return cf.name == "Event Sub Type" && cf.value != eventSubTypeArray[i].trim()
            });
            return eventSubTypes && eventSubTypes.length > 0;
          });
        }
        //console.log(eventsToFilter);
        allEventArray = eventsToFilter;
      }


      if (this.utilityService.excludeEventByName) {
        let eventNameExclusions = this.utilityService.excludeEventByName.split("~");
        let eventsToFilter = allEventArray;
        // console.log("Excluding Events By Name!")
        // console.log(eventNameExclusions);

        for (const i in eventNameExclusions) {
          eventsToFilter = eventsToFilter.filter(e => e.name.toLowerCase().trim() != eventNameExclusions[i].toLowerCase().trim());
        }
        allEventArray = eventsToFilter;
      }

      //console.log("Setting events");
      //console.log(allEventArray);
      this.filterdEvents = allEventArray;

    }

    /* ******************* SORT EVENTS BY DATE HERE*************** */


    if (!this.utilityService.includeBool && !this.utilityService.excludeBool) {
      this.filterdEvents = fullList;
    } else if (this.utilityService.includeEventSubType) {
      this.filterdEvents = fullList;
    }

    if (fullList.length == 0 || this.filterdEvents.length == 0) { //Determines whether to hide the widget or not
      if (this.widget) {
        this.hideWidget = true;
      }
    }

    // console.log("---------Filtered Events---------");
    // console.log(this.filterdEvents);
    // console.log(this.hideWidget);
  }


  getEvents() {
    this.utilityService.loading = true;
    let url = this.utilityService.getBaseUrl() + "api/event/events/";

    if (this.memberNumber != null && this.memberNumber.length > 0) {
      url += '?membershipId=' + this.memberNumber;
    }
    this.http.get(url, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      this.utilityService.loading = false;
      this.events = (<any>response).data;
      this.filterdEvents = (<any>response).data;
      //console.log(this.events);
      this.setupFiltersArrays();
    },
      err => {
        console.log('something went wrong in getting events');
        this.utilityService.loading = false;
        console.log(err);
      }
    );
  }

  getLinks() {
    let url = this.utilityService.getBaseUrl() + "api/event/links";
    this.http.get(url, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      let links = (<any>response).data;
      console.log('before', this.url + "");
      if (links && links.length > 0) {
        this.url = links[0];
        console.log('after', this.url);
        this.whfUrl = links[1];
      }
    },
      err => {
        console.log('something went wrong in getting links');
        console.log(err);
      }
    );
  }

  getEventsWithCal(location = null) {
    this.utilityService.loading = true;
    /* Production URL */
    let url = this.utilityService.getBaseUrl() + "api/event/EventsWithCal/";

    /*ACME LIVE API Testing URL */
    //let url  = "https://events.wisconsinhistory.org/api/event/EventsWithCal/";

    /*ACME DEV PLATFORM URL */
    //let url = "https://events-dev.wisconsinhistory.org/api/event/EventsWithCal/";

    if (location) url += location;
    /*if (this.memberNumber != null && this.memberNumber.length > 0) {
      url += '?membershipId=' + this.memberNumber;
    }*/
    if (this.utilityService.widget) {
      url += '?limit=' + this.utilityService.limit;
    }
    this.http.get(url, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      this.utilityService.loading = false;
      this.events = (<any>response).data;
      this.filterdEvents = (<any>response).data;
      //console.log(this.events);// All Events Response
      this.setupFiltersArrays();
    },
      err => {
        console.log('something went wrong in getting events');
        this.utilityService.loading = false;
        console.log(err);
      }
    );
  }

}

/* Sorting by date idea*/
/*
  I would probably sort the events by chronological order after all of the event inclusions and exclusions are done.
  That way you don't sort events that you aren't going to display. I would use the event property "start" (ex: 2022-12-07) to sort by 
  year first, then I would sort each year by month and then sort each month by day. I would ask if they want to further sort by start
  time which is also a property in each event.

  It would probably be easier to use the sort function after parsing all of the events and adding year,month,day properties to each event.
  This would be how I try to implement it

*/