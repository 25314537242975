import { Component, ElementRef } from '@angular/core';
import { UtilityService } from './Services/utility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'WHF';
  constructor(public _utilityService: UtilityService, private elementRef: ElementRef) {
    let location = this.elementRef.nativeElement.getAttribute('location');
    let width = this.elementRef.nativeElement.getAttribute('whfwidth');
    let baseURL = this.elementRef.nativeElement.getAttribute('baseurl');
    let morelink = this.elementRef.nativeElement.getAttribute('morelink');
    let limit = this.elementRef.nativeElement.getAttribute('limit');
    
    let includeLocation = this.elementRef.nativeElement.getAttribute('includeLocation');
    let includeEventType = this.elementRef.nativeElement.getAttribute('includeEventType');
    let includeEventSubType = this.elementRef.nativeElement.getAttribute('includeEventSubType');
    let excludeLocation = this.elementRef.nativeElement.getAttribute('excludeLocation');
    let excludeEventType = this.elementRef.nativeElement.getAttribute('excludeEventType');
    let excludeEventSubType = this.elementRef.nativeElement.getAttribute('excludeEventSubType');
    let excludeEventByName = this.elementRef.nativeElement.getAttribute('excludeEventByName');

    _utilityService.baseUrl = baseURL;
    
    if (location != null && location.trim() != "") {
      _utilityService.location = location;
    }
    if (width != null && width.trim() == 'narrow') {
      _utilityService.fullwidth = false;
    }

    if (width != null && width.trim() == 'widget') {
      _utilityService.widget = true;
      _utilityService.moreUrl = morelink;
      if (limit && limit.trim() != '')
        _utilityService.limit = limit;
    }
    
    if(includeLocation != null && includeLocation.trim() != ""){
      _utilityService.includeBool = true;
      _utilityService.includeLocation = includeLocation;
    }

    if(includeEventType != null && includeEventType.trim() != ""){
      _utilityService.includeBool = true;
      _utilityService.includeEventType = includeEventType;
    }

    if(includeEventSubType != null && includeEventSubType.trim() != ""){
      _utilityService.includeBool = true;
      _utilityService.includeEventSubType = includeEventSubType;
    }

    if(excludeLocation != null && excludeLocation.trim() != ""){
      _utilityService.excludeBool = true;
      _utilityService.excludeLocation = excludeLocation;
    }

    if(excludeEventType != null && excludeEventType.trim() != ""){
      _utilityService.excludeBool = true;
      _utilityService.excludeEventType = excludeEventType;
    }
    if(excludeEventSubType != null && excludeEventSubType.trim() != ""){
      _utilityService.excludeBool = true;
      _utilityService.excludeEventSubType = excludeEventSubType;
    }
    if(excludeEventByName != null && excludeEventByName.trim() != ""){
      _utilityService.excludeBool = true;
      _utilityService.excludeEventByName = excludeEventByName;
    }
  }
}
