<body>
  <div class="container-fluid p-0 containerWHF">
    <div class="row justify-content-center">
      <div id="circle" *ngIf="_utilityService.loading">
        <div class="loader">
          <div class="loader">
            <div class="loader">
              <div class="loader">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-list></app-list>
  </div>

</body>