<!-- Modal -->
<ng-template #eventInfo let-modal>
    <div class="modal-dialog-scrollable">
        <div class="modal-content site-content">
            <div class="modal-header">
                <h3 class="h3h4">{{selectedEvent.name}}</h3>
                <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>-->
            </div>
            <div class="modal-body p-3 pt-5 w-100">
                <div class="row">
                    <div class="col-12">
                        <div [innerHTML]="selectedEvent.description"></div>
                    </div>
                </div>
            </div>
            <div class="modal-footer modal-footer-shadow">
                <div class="col-12 text-center">
                    <span *ngIf="!selectedEvent.externalUrl && selectedEvent.calendar && selectedEvent.calendar.days.length == 0"
                        class="soldout m-1 pr-5 pl-5">
                        SOLD OUT
                </span>
                    <button *ngIf="!selectedEvent.externalUrl && selectedEvent.calendar && selectedEvent.calendar.days.length > 0"
                        type="button" class="m-1 pr-5 pl-5 listButton" (click)="openlink(selectedEvent.id)">BOOK
                        TICKETS</button>
                    <button *ngIf="selectedEvent.externalUrl && selectedEvent.calendar && selectedEvent.calendar.days.length > 0"
                        type="button" class="m-1 pr-5 pl-5 listButton"
                        (click)="openExternalLink(selectedEvent.externalUrl)">BOOK
                        TICKETS</button>

                    <a class="m-1 pr-5 pl-5" href="javascript:void(0)" (click)="modal.close('Close click')">
                        CLOSE
                    </a>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<!-- Date Modal -->
<ng-template #dateModal let-modal>
    <div class="modal-content">
        <div class="modal-body p-3 w-100">
            <div class="row">
                <div class="col-12">
                    <p>Date Filter</p>
                </div>
                <div class="col-12">
                    <div class="justify-content-center">
                        <button mat-button [ngClass]="{'dateSelected': selectedDateRange == 'All'}"
                            class="bgprimary mr-2 m-1"
                            (click)="selectedDateRange = 'All'; resetRange(); applyFilters();">
                            All
                        </button>
                        <button mat-button [ngClass]="{'dateSelected': selectedDateRange == 'This Week'}"
                            class="bgprimary mr-2 m-1"
                            (click)="selectedDateRange = 'This Week'; resetRange(); applyFilters();">
                            This Week
                        </button>
                        <button mat-button [ngClass]="{'dateSelected': selectedDateRange == 'Next Week'}"
                            class="bgprimary mr-2 m-1"
                            (click)="selectedDateRange = 'Next Week'; resetRange(); applyFilters();">
                            Next Week
                        </button>
                        <button mat-button [ngClass]="{'dateSelected': selectedDateRange == 'This Month'}"
                            class="bgprimary mr-2 m-1"
                            (click)="selectedDateRange = 'This Month'; resetRange(); applyFilters();">
                            This Month
                        </button>
                        <button mat-button [ngClass]="{'dateSelected': selectedDateRange == 'Next Month'}"
                            class="bgprimary mr-2 m-1"
                            (click)="selectedDateRange = 'Next Month'; resetRange(); applyFilters();">
                            Next Month
                        </button>
                    </div>
                </div>
                <div class="col-12">
                    <!--Inline calendar-->
                    <div class="text-center">
                        <mat-form-field appearance="fill" [style.width]="'100%'">
                            <mat-label>Select a date range</mat-label>
                            <mat-date-range-input [formGroup]="range" [rangePicker]="picker" disabled="false">
                                <input matStartDate formControlName="start" placeholder="Start date" disabled>
                                <input matEndDate formControlName="end" placeholder="End date" disabled>
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker" disabled="false"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker disabled="false" (dateInput)="updateDateRange()">
                            </mat-date-range-picker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="col-12 text-center">
                <button type="button" class="m-1 pr-5 pl-5 wfhdonebtn"
                    (click)="updateDateRange(); modal.close('Close click')">Done</button>
            </div>
        </div>
    </div>
</ng-template>

<div *ngIf="false" class="row justify-content-center">
    <div id="circle">
        <div class="loader">
            <div class="loader">
                <div class="loader">
                    <div class="loader">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid p-0" *ngIf="!hideWidget">
    <div class="container-fluid p-0" *ngIf="!utilityService.loading">

        <div class="header pb-6">
            <div class="row" style="min-height: 4rem;"></div>
        </div>

        <div class="row fixed" *ngIf="!widget">
            <div class="col-md-3 semi-clear" style="padding-top: 15px;">
                <mat-form-field appearance="fill" [style.width]="'100%'">
                    <mat-label>Search</mat-label>
                    <input matInput placeholder="Fun" [(ngModel)]="searchKeyword" (ngModelChange)="applyFilters()">
                </mat-form-field>
            </div>
            <div class="col-md-3" *ngIf="false">
                <mat-form-field appearance="fill" [style.width]="'100%'">
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Start date">
                        <input matEndDate formControlName="end" placeholder="End date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>

                    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start
                        date</mat-error>
                    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-3" style="padding-top: 15px;">
                <mat-form-field appearance="fill" [style.width]="'100%'"
                    (click)="this.utilityService.openModal(dateModal, 'md');">
                    <mat-label>Date</mat-label>
                    <input matInput placeholder="All" [(ngModel)]="selectedDateRange">
                    <mat-icon matSuffix>
                        <svg viewBox="0 0 24 24" width="24px" height="24px" fill="currentColor" focusable="false"
                            class="mat-datepicker-toggle-default-icon ng-star-inserted">
                            <path
                                d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z">
                            </path>
                        </svg>
                    </mat-icon>
                </mat-form-field>
            </div>
            <div class="col-md-3" style="padding-top: 15px;">
                <mat-form-field appearance="fill" [style.width]="'100%'">
                    <mat-label>Event</mat-label>
                    <mat-select [(ngModel)]="selectedEventType" (selectionChange)="applyFilters()">
                        <mat-option>-- None --</mat-option>
                        <mat-option *ngFor="let eventType of eventTypes" [value]="eventType">
                            {{eventType}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3" style="padding-top: 15px;" *ngIf="!utilityService.location">
                <mat-form-field appearance="fill" [style.width]="'100%'">
                    <mat-label>Location</mat-label>
                    <mat-select [(ngModel)]="selectedLocation" (selectionChange)="applyFilters()">
                        <mat-option>-- None --</mat-option>
                        <mat-option *ngFor="let location of locationArr" [value]="location">
                            {{location}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <br>
        <br>

        <div class="" *ngIf="!utilityService.loading">
            <div class="col-12">
                <div [ngClass]="{'parent': full && !widget, 'parentnarrow': !full && !widget, 'parentwidget': widget}"
                    *ngIf="events">
                    <ng-container *ngFor="let item of filterdEvents">
                        <div class="cardWHS eventbox" href="{{url}}/calendar?eventId={{item.id}}" target="_blank">
                            <mat-card class="WHFCard mb-3 mt-6">
                                <ng-container *ngIf="item?.images?.length > 0">
                                    <img *ngIf="item.calendar && item.calendar.days.length > 0" class="headerimg"
                                        mat-card-image src={{item?.images[0]?.screen}}>
                                    <div class="soldout-container"
                                        *ngIf="item.calendar && item.calendar.days.length == 0">
                                        <img class="headerimg soldoutmainimg" mat-card-image
                                            src={{item?.images[0]?.screen}}>
                                        <img *ngIf="(item.calendar && item.calendar.days.length == 0) || !item.calendar"
                                            class="headerimg soldoutimg" src="{{whfUrl}}/app/assets/soldout.png">
                                    </div>
                                </ng-container>
                                <div class="date" *ngIf="item.calendar && item.calendar.days.length > 1">
                                    <span class="month" *ngIf="false">{{item.calendar.days[0].date | date:'MMM'}}</span>
                                    <span class="month"
                                        *ngIf="!this.utilityService.checkIfMonthsAreEqueal(item.calendar.days[0].date, item.calendar.days[item.calendar.days.length -1].date)">{{item.calendar.days[0].date
                                        |
                                        date:'MMM'}}</span>
                                    <span class="year"
                                        *ngIf="!this.utilityService.checkIfMonthsAreEqueal(item.calendar.days[0].date, item.calendar.days[item.calendar.days.length -1].date)">thru</span>
                                    <span class="month">{{item.calendar.days[item.calendar.days.length -1].date |
                                        date:'MMM'}}</span>
                                </div>
                                <div class="date" *ngIf="item.calendar && item.calendar.days.length == 1">
                                    <span class="day">{{item.calendar.days[0].date | date:'dd'}}</span>
                                    <span class="month">{{item.calendar.days[0].date | date:'MMM'}}</span>
                                </div>
                                <mat-card-content>
                                    <h3 class="h3h4">{{item.name}}</h3>

                                    <mat-card-subtitle *ngIf="!utilityService.location && item.showLocation"
                                        style="color: var(--primary-color); font-size: 16px">
                                        {{item.siteName}} • {{item.location}}
                                    </mat-card-subtitle>

                                    <mat-card-subtitle *ngIf="utilityService.location || selectedLocation != null"
                                        style="color: var(--primary-color); font-size: 16px">
                                        {{item.location}}
                                    </mat-card-subtitle>

                                    <mat-card-subtitle style="font-size: 1rem; color: #000; text-transform:uppercase;"
                                        *ngIf="item.calendar && item.calendar.days.length > 1 && item.eventYear != item.currYear">
                                        <strong>{{item.calendar.days[0].date | date:'EE
                                            MMM d, y'}} - {{item.calendar.days[item.calendar.days.length -1].date
                                            |
                                            date:'EEE MMM d, y'}}</strong>
                                    </mat-card-subtitle>

                                    <mat-card-subtitle style="font-size: 1rem; color: #000; text-transform:uppercase;"
                                        *ngIf="item.calendar && item.calendar.days.length > 1 && item.eventYear == item.currYear">
                                        <strong>{{item.calendar.days[0].date | date:'EE,
                                            MMM d'}} - {{item.calendar.days[item.calendar.days.length -1].date
                                            |
                                            date:'EEE, MMM d'}}</strong>
                                    </mat-card-subtitle>

                                    <mat-card-subtitle style="font-size: 1rem; color: #000; text-transform:uppercase;"
                                        *ngIf="item.calendar && item.calendar.days.length == 1 && item.eventYear != item.currYear">
                                        <strong>{{item.calendar.days[0].date | date:'EE MMM d, y'}}</strong>
                                    </mat-card-subtitle>

                                    <mat-card-subtitle style="font-size: 1rem; color: #000; text-transform:uppercase;"
                                        *ngIf="item.calendar && item.calendar.days.length == 1 && item.eventYear == item.currYear">
                                        <strong>{{item.calendar.days[0].date | date:'EE, MMM d'}}</strong>
                                    </mat-card-subtitle>
                                </mat-card-content>
                                <mat-card-content>
                                    <p [innerHTML]="item?.shortDescription">
                                    </p>
                                    <div *ngIf="item.calendar.days.length > 1">
                                        <p style="font-style: italic">Multiple dates available</p>
                                    </div>
                                    <br>
                                    <br>
                                </mat-card-content>
                                <mat-card-footer>
                                    <a class="moreinfobutton m-3" href="javascript:void(0)"
                                        (click)="selectedEvent = item; this.utilityService.openModal(eventInfo, 'lg');">
                                        More Info
                                    </a>
                                    <a *ngIf="!item.externalUrl && item.calendar && item.calendar.days.length > 0"
                                        class="visit text-center" href="{{url}}/calendar?eventId={{item.id}}&cart"
                                        target="_blank">
                                        BOOK TICKETS
                                    </a>
                                    <div *ngIf="!item.externalUrl && item.calendar && item.calendar.days.length == 0"
                                        class="soldout text-center">
                                        SOLD OUT
                                    </div>
                                    <a *ngIf="item.externalUrl" class="visit text-center" href={{item.externalUrl}}
                                        target="_blank">
                                        BOOK TICKETS
                                    </a>
                                </mat-card-footer>
                            </mat-card>
                        </div>
                    </ng-container>
                </div>


                <div class="row" *ngIf="events && filterdEvents.length == 0">
                    <div class="col-12">
                        <div class="alert alert-warning alert-dismissible fade show" role="alert" *ngIf="!widget">
                            No events available. Sorry about that, try again.
                        </div>
                        <div class="alert alert-warning alert-dismissible fade show" role="alert" *ngIf="widget">
                            No events available at this time.
                        </div>
                    </div>
                </div>


                <div class="col-12" *ngIf="widget && utilityService.moreUrl != ''">
                    <br>
                    <br>
                    <br>
                    <br>
                    <a class="testButton" href={{this.utilityService.moreUrl}}>More Events</a>
                </div>

            </div>
        </div>
    </div>

    <div class="header pb-6">
        <div class="row" style="min-height: 4rem;"></div>
    </div>

</div>

<!--Only show this button when there are no events to be displayed/ when widget is hidden-->
<div class="col-12" *ngIf="widget && utilityService.moreUrl != '' && hideWidget">
    <br>
    <a class="testButton" href={{this.utilityService.moreUrl}}>More Events</a>

    <div class="header pb-6">
        <div class="row" style="min-height: 4rem;"></div>
    </div>


</div>