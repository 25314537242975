import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { getBaseUrl } from 'src/main';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  public corsKey: string = environment.corsKey;
  public tenantId: string = environment.tenantId;
  public corsUrl: string = environment.corsUrl;

  public location: string;

  public localCart: any;
  public membership: any;
  public loading: boolean = true;

  public fullwidth:boolean = true;
  public widget:boolean = false;

  public limit = 1;

  public moreUrl = '';

  public baseUrl = '';

  public excludeBool: boolean;
  
  public excludeLocation: string;
  public excludeEventType: string;
  public excludeEventSubType: string;
  public excludeEventByName: string;

  public includeBool: boolean;

  public includeLocation: string;
  public includeEventType: string;
  public includeEventSubType: string;



  constructor(private modalService: NgbModal, public http: HttpClient, private sanitizer: DomSanitizer) {
  }

  openModal(content, size) {
    this.modalService.open(content, { size: size ? size : 'lg' , centered: true });
  }

  getBaseUrl(): string {
    if(this.baseUrl && this.baseUrl != '') return this.baseUrl;

    return getBaseUrl();
  }


  setLocation(location: string) {
    this.location = location;
  }

  getUnSanatizedMessage(message) {
    return this.sanitizer.bypassSecurityTrustHtml(message);
  }

  compareEventsByDisplayOrder(a, b) {
    if (a.displayOrder < b.displayOrder) {
      return -1;
    }
    if (a.displayOrder > b.displayOrder) {
      return 1;
    }
    return 0;
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  formatTime(time) {
    if (time == null || time.trim() == "") {
      return "-";
    }
    let timeValues = time.split(':');
    var hours = timeValues[0];
    let minutes = timeValues[1];
    let suffix = (hours >= 12) ? 'PM' : 'AM';

    //only -12 from hours if it is greater than 12 (if not back at mid night)
    hours = (hours > 12) ? hours - 12 : hours;

    //if 00 then it is 12 am
    hours = (hours == '00') ? 12 : hours;

    return hours + ':' + minutes + ' ' + suffix;
  }


  checkIfMonthsAreEqueal(start, end): boolean{
    if(start && end){
      let date1 = new Date(start);
      let date2 = new Date(end);
      if(date1.getFullYear() == date2.getFullYear() && date1.getMonth() == date2.getMonth()){
        return true;
      }
    }
    return false;
  }


}